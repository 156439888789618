<template>
    <div style="width: 100%">
        <ElForm class="login-form">
            <h3 class="title33">{{ $t('TEXT0027') }}</h3>
            <el-form-item :error="input.error.id">
                <el-input
                        v-model="input.model.id"
                        maxlength="128"
                        size="large"
                        class="el-imput-icon"
                        :placeholder="$t('TEXT0093')"
                        tabIndex="1" autocomplete="on">
                    <i class="el-icon el-icon-c-scale-to-original" slot="prefix"/>
                </el-input>
            </el-form-item>
            <el-form-item :error="input.error.email">
                <el-input
                        v-model="input.model.email"
                        size="large"
                        class="fa-imput-icon"
                        :placeholder="$t('TEXT0019')">
                    <i class="fa fa-envelope" slot="prefix"/>
                </el-input>
            </el-form-item>
            <el-form-item>
                {{ $t('TEXT0094') }}
                <RouterLink to="/id/reset">{{ $t('TEXT0095') }}</RouterLink>
            </el-form-item>
            <ElButton type="primary"
                      :loading="loading.login"
                      @click.prevent="login">{{ $t('TEXT0027') }}
            </ElButton>
        </ElForm>
    </div>
</template>

<script>
import GuestApi from "@/api/GuestApi";
import EventBus from "@/config/EventBus";
import { decode } from 'js-base64';

export default {
    name: "Login",
    mounted() {
        EventBus.$on('localeChange', () => {
            this.setNull(this.input.error)
        })
    },
    data() {
        return {
            input: {
                model: {
                    id: null,
                    password: null
                },
                error: {
                    id: null,
                    password: null
                },
                variable: {
                    passwordType: 'password'
                }
            },
            loading: {
                login: false
            }
        }
    },
    methods: {
        login: function () {
            this.loading.login = true
            this.setNull(this.input.error)
            GuestApi.login(this.trimObject(this.input.model)).then(resp => resp.data).then(resp => {
                this.getGuestDetail()
                localStorage.setItem("token", resp.token);
                EventBus.$emit('updateNavBar')
                if (this.$route.query && this.$route.query.redirect) {
                    return this.$router.push(JSON.parse(decode(this.$route.query.redirect)))
                }
                this.$router.push('/kanji/convert/certificate')
            }).catch(resp => {
                if (resp.status === 412) {
                    for (let item of resp.data) {
                        console.log(item.key)
                        console.log(item.value)
                        this.input.error[item.key] = item.value
                    }
                    this.input.error.__ob__.dep.notify()
                }
            }).finally(() => {
                this.loading.login = false
            })
        },
        getGuestDetail:function (){
            GuestApi.getProfile().then(resp=>{
                localStorage.setItem('guestName', resp.data.kanji)
                localStorage.setItem('guestGender', resp.data.gender)
                EventBus.$emit('updateNavBar')
            })
        }
    }
}
</script>

<style scoped lang="scss">
@import "./src/style/variables.scss";
//

//
::v-deep .fa-imput-icon .el-input__prefix {
    font-size: 20px;
    left: 12px;
}

//
::v-deep .el-input--prefix .el-input__inner {
    padding-left: 40px !important;
}

@media screen and (max-width: 992px) { // mobile
    ::v-deep .el-input--prefix .el-input__inner {
        padding-left: 45px !important;
    }
}

</style>
